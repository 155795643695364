import { github } from "@code-surfer/themes"

export default {
  googleFont: 'https://fonts.googleapis.com/css?family=Didact+Gothic',
  fonts: {
    body: 'Didact Gothic',
    monospace: 'Roboto Mono, monospace'
  },
  ...github,
  styles: {
    h1: {
      marginBlockStart: '20px'
    },
    h3: {
      fontSize: '30px',
      marginBlockEnd: '0px'
    },
    p: {
      fontSize: '25px',
      lineHeight: '1.3'
    },
    ...github.styles
  }
}