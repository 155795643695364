import { Image, Appear, Notes } from 'mdx-deck';
import { CodeSurfer, CodeSurferColumns, Step } from 'code-surfer';
import SPARKK from "../../../../../../assets/sparkk.jpg";
import BIA from "../../../../../../assets/bia.jpg";
import WDG from "../../../../../../assets/wdg.png";
import LouisAnsa from "../../../../../../assets/louis.mp4";
import DespicableMe from "../../../../../../assets/despicableme.mp4";
import KOASD from "../../../../../../assets/killingofasacreddeer.mp4";
import OursRoux from "../../../../../../assets/oursroux.mp4";
import Mickey from "../../../../../../assets/mickey.mp4";
import SlowInSlowOut from "../../../../../../assets/06.webp";
import Timing from "../../../../../../assets/09.webp";
import DOM from "../../../../../../assets/dom.png";
import Thread from "../../../../../../assets/thread.png";
import custom from "../../../../../../theme";
import React from 'react';
export default {
  Image: Image,
  Appear: Appear,
  Notes: Notes,
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  SPARKK: SPARKK,
  BIA: BIA,
  WDG: WDG,
  LouisAnsa: LouisAnsa,
  DespicableMe: DespicableMe,
  KOASD: KOASD,
  OursRoux: OursRoux,
  Mickey: Mickey,
  SlowInSlowOut: SlowInSlowOut,
  Timing: Timing,
  DOM: DOM,
  Thread: Thread,
  custom: custom,
  React: React
};